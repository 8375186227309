import React from "react";
import { Button } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    logout({ returnTo: window.location.origin })
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleLogout}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
