import { Dialog, Divider, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import { useIntl } from 'react-intl';
import { RemoteParticipant } from 'twilio-video';
import { isParticipantAgent } from '../../utils';

const WaitingDialog = () => {
  const intl = useIntl();
  const participants = useParticipants();
  const isAgentMissing = !participants.some(({ identity }: RemoteParticipant) => isParticipantAgent(identity));
  
  const dialogNeedsOpeningRef = useRef(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!dialogNeedsOpeningRef.current && isAgentMissing) {
      setOpen(true);
      dialogNeedsOpeningRef.current = true;
    }
    if (!isAgentMissing){
        setOpen(false);
        dialogNeedsOpeningRef.current = true;
    }
  }, [isAgentMissing]);


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'waiting_for_agent' })}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WaitingDialog;
