import deLocales from '../translations/de.json';
import enLocales from '../translations/en.json';
import frLocales from '../translations/fr.json';

const availableLocales = ['de', 'en', 'fr'];
type Locale = typeof availableLocales[number];

type Locales = {
  [K in Locale]: any;
};

const locales: Locales = {
  de: deLocales,
  en: enLocales,
  fr: frLocales,
};

type i18nConfig = {
  defaultLocale: string;
  availableLocales: string[];
};
class LocaleService {
  private defaultLocale: string;
  private availableLocales: string[];
  constructor(config: i18nConfig) {
    this.defaultLocale = config.defaultLocale;
    this.availableLocales = config.availableLocales;
  }
  getAvailableLocales() {
    return this.availableLocales;
  }
  getDefaultLocale() {
    return this.defaultLocale;
  }
  getMessages(locale: string) {
    locale = locale.toLowerCase().split('-')[0];

    if (!availableLocales.includes(locale)) {
      locale = this.getDefaultLocale();
    }
    return locales[locale];
  }
}
export default new LocaleService({ defaultLocale: availableLocales[0], availableLocales });
