import React, { useEffect, useState } from 'react';
import BackgroundSelectionHeader from './BackgroundSelectionHeader/BackgroundSelectionHeader';
import BackgroundThumbnail, { ThumbnailType } from './BackgroundThumbnail/BackgroundThumbnail';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { staticImages as rawStaticImages, StaticImage } from '../VideoProvider/useBackgroundSettings/useBackgroundSettings';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import UploadBackground from './UploadBackground/UploadBackground';
import BackgroundImagesDB, { BackgroundImageRecord } from '../../utils/backgroundImagesDb';

interface BackgroundImageProxy<T extends BackgroundImageRecord | StaticImage> {
  backgroundImage: T;
  selected: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    display: 'flex',
    width: theme.rightDrawerWidth,
    height: (footerHeight) => `calc(100% - ${footerHeight >= 0 ? footerHeight : theme.footerHeight}px)`,
  },
  thumbnailContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    overflowY: 'auto',
  },
  fileInputContainer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'center',
  }
}));

interface BackgroundSelectionDialogProps {
  footerHeight?: number;
}

function BackgroundSelectionDialog(props: BackgroundSelectionDialogProps) {
  const classes = useStyles(props.footerHeight);
  const { isBackgroundSelectionOpen, setIsBackgroundSelectionOpen, setBackgroundSettings, backgroundSettings } = useVideoContext();
  const [customImages, setCustomImages] = useState<BackgroundImageProxy<BackgroundImageRecord>[]>([]);

  const proxiedStaticImages = rawStaticImages.map((img, idx) => {
    return {
      backgroundImage: img,
      selected: backgroundSettings.type === 'image' && backgroundSettings.index === idx,
    }
  });
  const [staticImages, setStaticImages] = useState<BackgroundImageProxy<StaticImage>[]>(proxiedStaticImages);

  useEffect(() => {
    const fetchCustomImages = async() => {
      const imgs = (await BackgroundImagesDB.orderedBackgroundImagesArray()).map((img: BackgroundImageRecord) => {
        return {
          backgroundImage: img,
          selected: backgroundSettings.type === 'uploadedFile' && backgroundSettings.index === img.id
        }
      });
      setCustomImages(imgs);
    };
    fetchCustomImages();
    // There is no need to call this again when backgroundSettings change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadedFile = async (file: File) => {
    const id = await BackgroundImagesDB.backgroundImages.add({ imageFile: file });
    setBackgroundSettings({ type: 'uploadedFile', uploadedFile: file, index: id });
    const image = {
      backgroundImage: (await BackgroundImagesDB.backgroundImages.get(id))!,
      selected: true,
    };
    const rest = customImages.map(img => {
      return {
        ...img,
        selected: false
      }
    });
    setCustomImages([image, ...rest]);
  };

  const unselectAllImages = () => {
    setCustomImages(customImages.map(img => {
      return { ...img, selected: false }
    }));
    setStaticImages(staticImages.map(img => {
      return { ...img, selected: false }
    }));
  };

  const onThumbnailClick = (type: ThumbnailType, index?: number) => {
    if (type === 'blur' || type === 'none') {
      unselectAllImages();
      setBackgroundSettings({ type });
    } else if (type === 'image') {
      setStaticImages(staticImages.map((img, idx) => {
        return {
          ...img, selected: idx === index
        };
      }));
      setBackgroundSettings({ type, index });
    } else if (type === 'uploadedFile') {
      setCustomImages(customImages.map(img => {
        return {
          ...img, selected: img.backgroundImage.id === index
        };
      }));
      const bg = customImages.find(img => img.backgroundImage.id === index);
      setBackgroundSettings({ type, index, uploadedFile: bg?.backgroundImage.imageFile });
    }
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isBackgroundSelectionOpen}
      transitionDuration={0}
      classes={{
        paper: classes.drawer,
      }}
    >
      <BackgroundSelectionHeader onClose={() => setIsBackgroundSelectionOpen(false)} />
      <UploadBackground onUploaded={handleUploadedFile} className={classes.fileInputContainer}/>
      <div className={classes.thumbnailContainer}>
        { /* No background */ }
        <BackgroundThumbnail onClick={onThumbnailClick} selected={backgroundSettings.type === 'none'} type={'none'} name={'None'} />
        { /* Blurred background */ }
        <BackgroundThumbnail onClick={onThumbnailClick} selected={backgroundSettings.type === 'blur'} type={'blur'} name={'Blur'} />
        { /* Custom backgrounds */ }
        {customImages.map((bgImage) => (
          <BackgroundThumbnail
            onClick={onThumbnailClick}
            selected={bgImage.selected}
            type={'uploadedFile'}
            key={`${bgImage.backgroundImage.imageFile.name}-${bgImage.backgroundImage.id}`}
            image={bgImage.backgroundImage.imageFile}
            index={bgImage.backgroundImage.id}
            name={bgImage.backgroundImage.imageFile.name.substring(0, 20)}
          />
        ))}
        { /* Preconfigured static backgrounds */ }
        {staticImages.map((image, index) => (
          <BackgroundThumbnail
            onClick={onThumbnailClick}
            selected={image.selected}
            type={'image'}
            name={image.backgroundImage.name}
            index={index}
            imagePath={image.backgroundImage.path}
            key={image.backgroundImage.path}
          />
        ))}
      </div>
    </Drawer>
  );
}

export default BackgroundSelectionDialog;
