import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@material-ui/core';
import { useAppState } from '../../state';
import logo from '../../images/tourlane.png';

export default function Nothing() {
  const { isAuthenticated,  isLoading: isAuth0Loading } = useAuth0();
  const { isFetching } = useAppState();
  const TourlaneLogo = () => <img width={100} alt="Tourlane Logo" src={logo}></img>

  return (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {(!isAuthenticated && (isAuth0Loading || isFetching)) ? <CircularProgress size={100} /> : <TourlaneLogo />}
    </div>
  )
}
