import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ handleSubmit }: RoomNameScreenProps) {
  const intl = useIntl();
  const classes = useStyles();
  const { user, setUser } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const u = user || {}
    setUser({ ...u, displayName: event.target.value })
  };

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        { intl.formatMessage({ id: 'enter_your_name' }) }
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-user-name">
              { intl.formatMessage({ id: 'your_name' }) }
            </InputLabel>
            <TextField
              id="input-user-name"
              variant="outlined"
              fullWidth
              size="small"
              value={user?.displayName || ''}
              onChange={handleNameChange}
            />
          </div>
        </div>
        <Grid container justifyContent="flex-end">
          <Button variant="contained" type="submit" color="primary" disabled={!user?.displayName} className={classes.continueButton}>
            { intl.formatMessage({ id: 'button.continue' }) }
          </Button>
        </Grid>
      </form>
    </>
  );
}
