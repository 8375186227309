import { useEffect, useRef } from 'react';
import { RemoteParticipant } from 'twilio-video';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import useParticipants from '../useParticipants/useParticipants';
import { isParticipantAgent } from '../../utils';

/**
 * Hook to track participant scenarios:
 * 1. Customer joins and leaves as the only participant
 * 2. Agent joins but leaves quickly (under 5 minutes) with customer present in the call
 */
export function useParticipantTracking(isAuthenticated: boolean) {
  const initialParticipantsCountRef = useRef(0);
  const callStartTimeRef = useRef<Date | null>(null);
  const { travelRequestUuid } = useParams<{ travelRequestUuid: string }>();
  const participants = useParticipants();

  useEffect(() => {
    if (participants.length > initialParticipantsCountRef.current) {
      initialParticipantsCountRef.current = participants.length;
    }

    callStartTimeRef.current = new Date();

    const handleBeforeUnload = () => {
      const isAgentMissing = !participants.some(({ identity }: RemoteParticipant) => isParticipantAgent(identity));
      if (isAgentMissing && initialParticipantsCountRef.current === 1 && !isAuthenticated) {
        // Log when customer closes the window as the only participant
        Sentry.captureMessage('Customer closed the video call without other participants joining', {
          extra: {
            travelRequestUuid,
          },
        });
      } else if (initialParticipantsCountRef.current > 1 && isAuthenticated && callStartTimeRef.current) {
        // Log when agents closes the window within 5 minutes with other participants have joined the call
        const elapsedMilliseconds = new Date().getTime() - callStartTimeRef.current.getTime();
        const elapsedSeconds = elapsedMilliseconds / 1000;
        if (elapsedSeconds < 300) {
          Sentry.captureMessage(`Agent closed the video call with customer being connected after a short time`, {
            extra: {
              travelRequestUuid,
            },
          });
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isAuthenticated, travelRequestUuid, participants]);
}
