import React, { useRef } from 'react';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';

interface UploadBackgroundProps {
  className?: string;
  onUploaded(file: File): void;
}

function UploadBackground({ className, onUploaded }: UploadBackgroundProps) {
  const intl = useIntl();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && /^image\/.+$/.test(file.type)) {
      onUploaded(file);
    }
  }
  
  return (
      <div className={className}>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
          value={''}
          accept="image/*"
        />
        <Button size="large" variant="contained" color="primary" onClick={() => fileInputRef.current?.click()}>
          { intl.formatMessage({ id: 'upload_background' }) }
        </Button>
      </div>
  )
}

export default UploadBackground;
