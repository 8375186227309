import Dexie, { Table } from 'dexie';

export interface BackgroundImageRecord {
  id?: number; // this will be auto incremented and auto assigned
  imageFile: File;
}

class BackgroundImagesDb extends Dexie {
  public backgroundImages!: Table<BackgroundImageRecord, number>;

  public constructor() {
    super("BackgroundImagesDb");
    this.version(1).stores({
      backgroundImages: '++id,imageFile'
    });
  }

  public orderedBackgroundImagesArray() {
    return this.backgroundImages.orderBy(':id').reverse().toArray();
  }
}

const BackgroundImagesDB = new BackgroundImagesDb();
export default BackgroundImagesDB;
