import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { needsAuth } from '../../utils';

export default function PrivateRoute({ component, ...args }: RouteProps) {
  if (component && needsAuth()) {
    return <Route component={withAuthenticationRequired(component)} {...args} />
  } else {
    return <Route component={component} {...args} />
  }
};
